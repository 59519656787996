<template>
    <div>
        <v-container >
            <v-form
                v-model="validaCovid"
                onSubmit="return false"
                @submit="guarda"
            >
                <v-card>
                    <v-img
                        class="white--text align-top"
                        height="200px"
                        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                        src="/images/virus-paciente.jpg"
                    >
                        <v-card-title justify-top>
                            <v-btn text icon to="/urgencias/covid">
                                <v-icon class="white--text">
                                    mdi-arrow-left
                                </v-icon>
                            </v-btn>
                            Detalles de la atención
                        </v-card-title>
                    </v-img>
                
                    <v-card-text>
                        <v-toolbar short dense color="blue darken-3" dark flat>
                            <v-toolbar-title>Datos del paciente</v-toolbar-title>
                        </v-toolbar>
                        <v-sheet elevation="2" class="pa-2">
                            <v-row> 
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <b>Nombre:</b><br/>
                                    {{paciente.nombreCompleto}}
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <b>C.U.R.P.:</b><br/>
                                    {{paciente.curo}}
                                </v-col>
                                
                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <b>Fecha de nacimiento:</b><br/>
                                    {{paciente.fechaNacimiento}}
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <b>Sexo:</b><br/>
                                    {{paciente.sexo}}
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <b>Municipio:</b><br/>
                                    {{paciente.municipioNom}}
                                </v-col>

                                <v-col xs="12" sm="6" md="4" lg="4" xl="4" cols="12">
                                    <b>Localidad:</b><br/>
                                    {{paciente.localidadNom}}
                                </v-col>
                            </v-row>
                        </v-sheet>


                            <v-alert
                            v-if="!datosCovid.id"
                            class="my-1"
                            border="left"
                            dense
                            text
                            type="warning"
                        >
                            Estos registros no han sido guardados
                        </v-alert>


                        <v-sheet elevation="2" class="mt-2 pa-2">
                            <v-toolbar short dense color="blue darken-3" dark flat>
                                <v-toolbar-title>Registro de la atención</v-toolbar-title>
                            </v-toolbar>
                            <v-row no-gutters class="mt-2"> 
                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-select
                                        class="px-1"
                                        v-model="datosCovid.sintomas"
                                        :items="itemsSintomas"
                                        :rules="[
                                            value => value.length >0 || 'Requerido.',
                                        ]"
                                        label="Síntomas que ha presentado"
                                        multiple
                                        chips
                                        rounded
                                        outlined
                                    />
                                </v-col>
                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-select
                                        class="px-1"
                                        v-model="datosCovid.comorbilidades"
                                        :items="comorbilidadesList"
                                        :rules="[
                                            value => value.length >0 || 'Requerido.',
                                        ]"
                                        label="Comorbilidades"
                                        multiple
                                        chips
                                        rounded
                                        outlined
                                    />
                                </v-col>
                                
                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        class="px-1"
                                        v-model="datosCovid.analgesicos"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        label="Analgésicos"
                                    ></v-text-field>
                                </v-col>
                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        class="px-1"
                                        v-model="datosCovid.analgesicosFecha"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        label="Fecha de administración"
                                    ></v-text-field>
                                </v-col>

                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        class="px-1"
                                        v-model="datosCovid.antivirales"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        label="Antivirales"
                                    ></v-text-field>
                                </v-col>
                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        class="px-1"
                                        v-model="datosCovid.antiviralesFecha"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        label="Fecha de administración"
                                    ></v-text-field>
                                </v-col>

                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        class="px-1"
                                        v-model="datosCovid.antibioticos"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        label="Antibióticos"
                                    ></v-text-field>
                                </v-col>
                                <v-col xs="12" sm="6" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        class="px-1"
                                        v-model="datosCovid.antibioticosFecha"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        label="Fecha de administración"
                                    ></v-text-field>
                                </v-col>
                                <v-btn
                                    color="success"
                                    type="submit"
                                    :disabled="!validaCovid"
                                    :loading="loadingCovid"
                                >
                                    Guardar/Actualizar
                                </v-btn>
                            </v-row>
                        </v-sheet>
                        <v-sheet elevation="2" class="mt-2 pa-2">
                            <v-toolbar short dense color="blue darken-3" dark flat>
                                <v-toolbar-title>Notas</v-toolbar-title>
                                <v-spacer/>
                                <v-btn icon @click="recargaNotas=true">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-toolbar>

                            <v-btn class="ml-10 mt-2" rounded outlined color="green" @click="datosNuevaNota={id:2, tipoNota:'Urgencias'}" :disabled="Boolean(datosNuevaNota.id)">
                                <v-icon  left>mdi-file-plus-outline</v-icon> Nueva nota
                            </v-btn>

                            <paciente-notas
                                :internamiento="internamiento"
                                :recargaNotas.sync="recargaNotas"
                                :permiteAgrega='false'
                                :datoNotaExterna.sync='datosNuevaNota'
                            />
                        </v-sheet>

                    </v-card-text>


                </v-card>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import PacienteNotas from '@/components/hospital/Notas'
export default {
    components:{
        'paciente-notas':PacienteNotas,
    },
    mounted(){
        this.buscaInternamiento(this.internamiento)
        //console.log('carga datos COVID')
    },
    data:()=>({
        paciente:{},
        datosInternamiento:{},

        datosNuevaNota:{},

        recargaNotas:false,
        
        datosCovid:{
            id:null,
            sintomas:[],
            comorbilidad:[],
            analgesicos:'',
            analgesicosFecha:'',
            antivirales:'',
            antiviralesFecha:'',
            antibioticos:'',
            antibioticosFecha:'',
            nota:'',
        },

        itemsSintomas:[
            'Fiebre','Tos','Cefalea','Disnea','Irritablidad','Diarrea',
            'Dolor torácico','Escalofrios','Odinofagia','Mialgias','Artralalgias','Ataque al estado en general',
            'Rinorrea','Polipnea','Vómito','Dolor abdominal','Conjuntivitis','Cianosis','Otros','Ninguno'
        ],
        comorbilidadesList:[
            'Diabetes','EPOC','Asma','Inmunosupresión','VIH/SIDA','Enfermedad cardiovascular',
            'Obesidad','Insuficiencia renal','Tabaquismo','Otros','Ninguno'
        ],
        
        validaCovid:false,
        loadingCovid:false,
    }),
    methods:{
        async guarda(){
            this.loadingCovid = true
            let params = this.datosCovid
            params.internamiento=this.internamiento

            try{
                let dc = await this.$http({
                    method:'POST',
                    url:'/urgencias/guardaCovid',
                    data:params
                })

                if (dc.data.estado == true){
                    this.buscaInternamiento(this.internamiento)
                    this.loadingCovid = false
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Actualizado',
                        text: 'Datos guardados correctamente',
                    })
                }else {
                    this.loadingCovid = false
                    console.log(dc.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingCovid = false
            }
            
        },
        async buscaInternamiento(val){
            try{
                let int = await this.$http({
                    url:'/movimientos/datosencuentro',
                    method:'GET',
                    params:{id:val}
                })
                if (int.data.success){
                    this.datosInternamiento = int.data.data
                    let pac = await this.$http({
                        url:'/pacientes/listV2',
                        method:'GET',
                        params:{id:int.data.data.cip}
                    })

                    this.paciente= pac.data

                    let cov = await this.$http({
                        url:'/urgencias/listCovid',
                        method:'GET',
                        params:{internamiento:val}
                    })
                    if (cov.data.estado == true){
                        //console.log(cov.data)
                        cov.data.root.sintomas = cov.data.root.sintomas.split(',')
                        cov.data.root.comorbilidades = cov.data.root.comorbilidades.split(',')
                        this.datosCovid = cov.data.root
                    } 


                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede cargar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSigno = false
            }
        },

        async buscaPaciente(val){
            // datosCovid
            try{
                let pac = await this.$http({
                    url:'/pacientes/listV2',
                    method:'GET',
                    params:{id:val}
                })
                //console.log(pac.data.pacienteId)
                if (pac.data.pacienteId){
                    this.paciente = pac.data
                    //console.log('SI')
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede cargar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSigno = false
            }
        },
        
    },
    computed:{
        internamiento(){
            return parseInt(this.$route.params.id)
        },
    }

}
</script>

<style>

</style>